/* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 41, 55, 0.64);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
  font-family: "Inter";
}

.modal-component {
  display: block;
  width: 400px;
  padding: 58px 34px;
  background-color: white;
  position: relative;
  max-width: 96vw;
  border-radius: 12px;
  overflow: hidden;
  border: 1px #e5e7eb;
}

.modal-title {
  margin-bottom: 12px;
  font-size: 24px;
  font-family: Lora;
  font-weight: 400;
  letter-spacing: 0.12px;
  word-wrap: break-word;
}

.modal-content {
  color: #575f69;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 22.4px;
  word-wrap: break-word;
}

.modal-button-agree {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  width: 275px;
  height: 45px;
}

.modal-cross {
  position: absolute;
  top: 11px;
  right: 13px;
  cursor: pointer;
}
