.avatar-component-parent-expanded {
  z-index: 10;
}

.alert {
  z-index: 3000000000 !important;
}

.create-profile-tracker {
  z-index: 8;
}

.dropdown {
  z-index: 7;
}

.dropdown-component {
  z-index: 7;
}

.header {
  z-index: 9;
}

.mobile-header-container {
  z-index: 9;
}

.new-profile-footer {
  z-index: 9;
}

.toast {
  z-index: 9;
}

.image-expander {
  z-index: 9 !important;
}

.image-expand-icon {
  z-index: 2;
}

svg {
  z-index: 1 !important;
}
