.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 12px;
  border: 1px #e5e7eb solid;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.dropdown-item {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  color: #212c0a;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
  width: 100%;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.dropdown-item.active {
  background-color: #e0e0e0;
}

.dropdown-item span {
  font-size: 1rem;
  color: #555;
}
