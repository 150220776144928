.signup-container {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 86px;
}

.credentials-container {
  text-align: left;
}

.signup-left-side {
  display: flex;
  width: 100%;
  justify-content: center;
}

.get-started-button {
  width: 400px;
  height: 46px;
  background: #1f2937;
  border-radius: 8px;
  margin-top: 28px;
}

.creator-questions {
  position: relative;
}

.creator-question {
  display: block;
  width: 312px;
}

.creator-sub-question {
  width: 335px;
  line-height: 20px;
}

.continue-button {
  margin-left: 13px;
  height: 46px;
  border-radius: 25px;
  border: 1px #ff7262 solid;
  width: 141px;
  margin-bottom: 60px;
}
/* ----- ----------------------------------*/
@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.slide-in-up {
  animation: slideInUp 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

.slide-out-up {
  animation: slideOutUp 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.slide-in-up,
.slide-out-up {
  will-change: transform, opacity;
}
