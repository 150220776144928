/* Small screens */
@media only screen and (max-width: 639px) {
  .signup-left-side {
    width: 100% !important;
  }
  .regular-input {
    width: 92vw !important;
    max-width: 400px;
  }
  .get-started-button {
    width: 92vw !important;
    max-width: 400px;
  }
  .step-description {
    margin-top: 24px;
  }
  .input-textarea {
    max-width: calc(100vw - 64px) !important;
  }
  .responsive-60-40 {
    margin-bottom: 40px !important;
  }
  .next-step-button {
    /* width: 92vw !important;
    max-width: 400px !important; */
  }
  .drag-creator-photo-box {
    width: 92vw !important;
    max-width: 400px !important;
  }
  .creator-photos-preview-container {
    width: 92vw !important;
    max-width: 400px !important;
  }
  .checkbox-container {
    width: 92vw !important;
    max-width: 400px !important;
  }
  .profile-tile {
    margin-right: 0 !important;
    width: 92vw !important;
    max-width: 430px !important;
  }

  .header-container {
    display: none !important;
  }

  .landing-page {
    margin-top: 69px !important;
  }

  .landing-input-container {
    margin-top: 35px !important;
    margin-bottom: 59px !important;
  }

  .landing-input {
    width: 382px !important;
    max-width: calc(100vw - 48px) !important;
  }

  .landing-input input {
    width: 276px !important;
    max-width: calc(100vw - 170px) !important;
  }

  .landing-input input::placeholder {
    max-width: 95% !important;
    overflow: ellipsis !important;
  }

  .signup-container {
    margin-top: 69px !important;
  }

  .explore-container {
    padding: 0 16px !important;
    max-width: 100vw;
    overflow: hidden;
  }

  .search-results-section {
    padding: 0 !important;
  }

  .profile-container {
    padding: 0 !important;
    /* justify-content: center !important; */
    border-top: 1px #e5e7eb;
  }

  .preview-profile-container {
    margin: 76px 0px !important;
  }

  .profile-mode .summary-role {
    padding-left: 15px !important;
  }

  .summary-details-container {
    padding: 0 16.5px 0px 16.5px !important ;
  }

  .explore-search {
    max-width: calc(100vw - 32px) !important;
  }

  .profile-tile-container {
    margin: 0 !important;
  }

  .profile-tile-2 {
    width: 100vw !important;
    padding: 23px 0px !important;
    border-radius: 0 !important;
  }

  .profile-tile-1 {
    margin: 0 !important;
  }

  .photos-section {
    display: block !important;
  }

  .photo-column {
    width: 100% !important;
  }

  .creator-questions-container {
    margin-top: 70px !important;
  }

  .create-page-button {
    height: 46px !important;
    width: 104px !important;
  }

  .create-page-button span {
    font-size: 12px !important;
  }

  .explore-designers-button span {
    font-size: 12px !important;
  }

  .burger-create-page-button {
    width: 200px !important;
  }

  .progress-tracker {
    padding-left: 4vw !important;
    padding-right: 20vw !important;
    width: auto !important;
    overflow-x: auto !important;
    white-space: nowrap !important;
    display: inline-block;
    align-items: center;
  }

  .create-profile-tracker {
    top: 76px !important;
  }

  .alert {
    top: 64px !important;
    padding: 10px 20px !important;
  }

  .new-profile-footer {
    left: 0% !important;
    width: 100vw !important;
    transform: translate(0) !important;
  }

  .profile-tile-feedback-container {
    margin-right: 0 !important;
    margin-bottom: 13px !important;
  }

  .feedback-container {
    display: flex;
    justify-content: center !important;
    padding: 13px 0 !important;
  }

  .align-center {
    display: flex !important;
    justify-content: center !important;
    max-width: 100vw !important;
    overflow: hidden !important;
  }

  .transparent-button {
    max-width: 96vw !important;
  }

  .feedback-success-svg {
    width: 400px !important;
    max-width: 96vw !important;
  }

  .feedback-complete-button {
    margin-top: 42px;
  }

  .profile-badge-avatar {
    margin-right: 10px !important;
  }

  .profile-badge-name {
    display: none !important;
  }

  .maintenance-banner {
    display: none !important;
  }

  .pricing-modal {
    padding: 33px 14px 15px 14px !important;
  }

  .heading-secondary {
    line-height: 30px !important;
  }

  .search-result-text {
    padding: 0 !important;
  }

  .explore-wrapper {
    padding-left: 0px !important;
  }

  .summary-container {
    width: 100% !important;
  }

  .profile-tile-summary-role {
    border-radius: 0px !important;
    border-top: 1px #e5e7eb solid !important;
    border-right: none !important;
    border-left: none !important;
    width: 100vw !important;
  }

  .profile-tile-details {
    border: none !important;
  }
  .theme-box-wrapper {
    max-width: calc(100vw - 32px);
  }
}

/* Medium screens */
@media only screen and (min-width: 640px) and (max-width: 1023px) {
  .responsive-60-40 {
    margin-bottom: 40px !important;
  }

  .mobile-header-container {
    display: none !important;
  }

  .kydoscope {
    display: none !important;
  }

  .no-support {
    display: flex !important;
  }
}

/* Large screens */
@media only screen and (min-width: 1024px) {
  .responsive-60-40 {
    margin-bottom: 40px !important;
  }

  .mobile-header-container {
    display: none !important;
  }
}
