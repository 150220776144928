.feedback-container {
  max-width: 96vw;
  padding: 13px 40px;
  display: flex;
}

.profile-tile-feedback-container {
  margin-right: 61px;
}

.feedback-tile {
  padding: 31px 31px 46.88px 31px;
  background: white;
  border-radius: 12px;
  border: 1px #e5e7eb solid;
  width: 400px;
  max-width: 96vw;
}

.feedback-question-container {
  padding-bottom: 20px;
  border-bottom: 1px #e5e7eb solid;
}

.feedback-ratings-container {
  padding-top: 15.56px;
}

.feedback-button-container {
  margin-top: 42px;
  width: 400px;
  max-width: 96vw;
}

.feedback-container html {
  scroll-behavior: smooth;
}

.feedback-complete-button {
  margin-top: 42px;
}
