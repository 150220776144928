.selected-option circle {
  fill: #34a853;
}

.selected-option path:first-of-type {
  fill: white;
}

.selected-option .option-name {
  fill: #34a853;
}
