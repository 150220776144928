.create-profile-tracker {
  display: flex;
  padding: 19px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  background: white;
  top: 84px;
}
