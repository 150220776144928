.page-transition {
  position: relative;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  opacity: 1;
  box-shadow: none;
}

.page-transition.transitioning {
  opacity: 0.8; /* Slight fade effect */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}
