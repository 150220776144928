.avatar-component-container {
  display: inline-block;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  box-shadow: 0px 4px 11.4px rgba(0, 0, 0, 0.1);
  border: 3px solid white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.avatar-component-container-expanded {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70vw;
  width: 70vw;
  border-radius: 100%;
  box-shadow: 0px 8px 22.8px rgba(0, 0, 0, 0.2);
}

.avatar-component-parent-expanded {
  display: inline-block;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: #202020;
  position: fixed;
  top: 0;
  left: 0;
}

.default-avatar {
  display: flex;
  color: #991b1b;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  word-wrap: break-word;
  background: #fee2e2;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
}
