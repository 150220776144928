.publish-wrapper {
  display: inline-block;
}

.publish-button {
  width: 279px;
  height: 46px;
  color: white;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: black;
  border-radius: 25px;
  margin-left: 13px;
}

.button-section {
  width: 100%;
}

.step-info {
  max-width: 394px;
}

.step-description {
  margin-top: 12px;
}

.next-step-button {
  /* width: 400px; */
  height: 46px;
  color: white;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: #ff5043;
  border-radius: 8px;
}
