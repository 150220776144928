.get-rating-component {
  padding: 47px 0;
  max-width: 400px;
}

.get-rating-component-header {
  text-align: center;
  padding: 0 33px;
  margin-bottom: 36px;
}

.get-rating-component-content {
  padding: 0 15px;
  margin-bottom: 64px;
}

.get-rating-component-buttons {
  padding: 0 15px;
}
