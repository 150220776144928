.button-component {
  all: unset; /* Resets all default styling from the button */
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparent-button {
  width: 400px;
  height: 46px;
  color: #6b7280;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: transparent;
  border-radius: 8px;
}

.button-medium {
  width: 113px !important;
  height: 46px;
  color: #1f2937;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: #e5e7eb;
  border-radius: 8px;
}

.button-large-round {
  width: 218px !important;
  height: 46px;
  border-radius: 999px;
  border: 1px rgba(31, 41, 55, 0.04) solid;
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: #1f2937;
}

.button-full-width {
  width: 100% !important;
}
