.checkbox-container {
  display: flex;
  height: 52px;
  width: 400px;
  padding-left: 16px;
  padding-right: 16px;
  background: white;
  border: 1px #e5e7eb solid;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
}

.checkbox-input {
  display: none;
}

.checked-box {
  display: none;
  cursor: pointer;
}

.un-checked-box {
  display: none;
  cursor: pointer;
}

.checkbox-input:not(:checked) ~ .checked-box {
  margin-top: 6px;
  display: inline-block;
}

.checkbox-input:checked ~ .un-checked-box {
  margin-top: 6px;
  display: inline-block;
}

.checkbox-label {
  color: #1f2937;
  font-size: 16px;
  font-family: var(--font-ternary);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
  margin-left: 16px;
}
