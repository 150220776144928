:root {
  --font-primary: "Inter";
  --font-secondary: "Inter";
  --font-ternary: "Inter";
  --font-quaternary: "Lora";
}

body {
  all: unset;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  max-width: 100%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Loader.css */
.loader {
  position: relative;
  overflow: hidden;
  /* background: #f6f7f8; */
  opacity: 0.6;
  pointer-events: none;
}

.loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.8) 50%,
    transparent 100%
  );
  animation: loaderAnimation 2.5s infinite linear;
}

@keyframes loaderAnimation {
  0% {
    left: -150%;
  }
  50% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}

.skeleton .text,
.skeleton .state-box-value,
.skeleton .state-box-label,
.skeleton .position-at {
  background: #e0e0e0;
  color: transparent !important;
}

.skeleton .profile-stats {
  color: transparent !important;
}

.skeleton .link {
  background: #e0e0e0;
  color: transparent !important;
}

.skeleton svg {
  fill: #e0e0e0 !important;
  stroke: none !important;
}

.skeleton svg * {
  fill: #e0e0e0 !important;
  stroke: none !important;
}

.skeleton .creator-photo {
  background-color: #e0e0e0;
}

.skeleton img {
  opacity: 0;
}

.skeleton .button-component {
  background: #e0e0e0;
  color: transparent !important;
  border: none;
}

.skeleton .avatar-component-parent {
  /* display: none !important; */
}

.skeleton .avatar-component-container {
  background-image: none !important;
}

.skeleton .default-avatar {
  height: 90px;
  width: 90px;
  border-radius: 100%;
  box-shadow: 0px 8px 22.8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  color: transparent;
}

.header-back-button {
  cursor: pointer;
}

.header-back-button:hover {
  opacity: 0.8;
}

svg {
  margin: 0;
  padding: 0;
}

.divider {
  border-bottom: 1px #e5e7eb solid;
}

.app-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.no-support {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: none;
  color: #575f69;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 19.6px;
  word-wrap: break-word;
}

/* Target the scrollbar itself */
::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

/* Target the scrollbar track (the part the scrollbar moves along) */
::-webkit-scrollbar-track {
  background: transparent; /* Make the track invisible */
}

/* Target the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1); /* Use a very light color or transparent */
  border-radius: 2px; /* Optional: adds rounded corners */
}

.underline {
  text-decoration: underline;
}

/* Hide up and down arrows in WebKit browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.document-component {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 20px 0;
}

.document-component-wrapper {
  width: 600px;
  max-width: calc(100vw - 32px);
}

.document-content {
  width: 100%;
  color: #575f69;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  margin-top: 20px;
}
