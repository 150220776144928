@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.alert {
  width: 100%;
  padding: 18px 10px;
  color: #575f69;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 12px;
  animation: slideIn 0.5s forwards;
  text-align: center;
  line-height: 20px;
}

.alert.slideOut {
  animation: slideOut 0.5s forwards;
}

.error {
  background-color: rgba(255, 114, 98, 0.11); /* Red for error */
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
}

.success {
  background-color: rgba(189.06, 232.69, 196.04, 0.2); /* Green for success */
}

.info {
  background-color: #2196f3; /* Blue for info */
}

.closeButton {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
}
