.explore-container {
  padding: 25px 0px;
}

.explore-tools {
  padding: 0 41px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.explore-button {
  padding: 0 12px;
  height: 48px;
  border-radius: 8px;
  display: inline-block;
  margin-right: 8px;
}

.explore-search {
  width: 399px;
  height: 48px;
  border-radius: 12px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
}

.explore-more-button {
  width: 225px;
  height: 48px;
  color: white;
  font-size: 14px;
  font-family: Helvetica Neue;
  font-weight: 500;
  background: #1f2937;
  border-radius: 12px;
  font-family: inter;
}

.explore-tools-mobile-buttons {
  max-width: 100%;
  overflow-x: scroll;
  text-wrap: nowrap;
}

.profile-summary-container:nth-of-type(3n) {
  margin-right: 0 !important;
}

.profile-summary-container:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.search-result-text {
  padding: 0 41px;
}

.explore-wrapper {
  padding-left: 0px;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.summary-container {
  width: 1229px;
}
