.profile-summary {
  display: inline-block;
  background: white;
  border-radius: 12px;
  border: 1px #e5e7eb solid;
  width: 399px;
  max-width: calc(100vw - 32px);
}

.summary-role {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 69px;
  padding: 20px 22px 0 31px;
  background: #f9fafc;
  border-bottom: 1px #e5e7eb solid;
  border-radius: 12px;
}

.summary-details-container {
  display: block;
  margin-top: 54px;
  /* width: 333px; */
  padding: 0 31px 28px 31px;
}

.name-details {
  display: block;
  width: 333px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.rating-details-header {
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}

.profile-mode {
  background: white;
  border-radius: 12px;
  border: 1px #e5e7eb solid;
  width: 400px !important;
}

.profile-mode .summary-details-container {
  margin-top: 73px;
  padding-bottom: 0;
}

.profile-mode .rating-details-header {
  margin-bottom: 20px;
}

.profile-tile-buttons {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.profile-tile-button-1 {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: #1f2937;
  border-radius: 8px;
  width: 133px;
  height: 38px;
  margin-right: 20px;
}

.profile-tile-button-2 {
  color: #1f2937;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  width: 118px;
  height: 36px;
  margin-right: 20px;
  border: 1px #1f2937 solid;
  border-radius: 8px;
}

.feedback-mode .summary-details-container {
  padding-bottom: 0;
}
