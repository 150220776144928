.input-container {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.input-field {
  all: unset;
  width: 100%;
  color: #212c0a;
  font-size: 16px;
  font-family: var(--font-secondary);
  font-weight: 400;
  word-wrap: break-word;
}

.input-placeholder {
  position: absolute;
  pointer-events: none;
}

/* Cursor blink animation */
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Apply the animation to the cursor */
.input-field::after {
  content: "|"; /* the cursor */
  animation: blink 1s step-end infinite;
  opacity: 0;
}

.input-field::placeholder {
  color: #9ca3af;
  font-size: 16px;
  font-family: var(--font-secondary);
  font-weight: 400;
  word-wrap: break-word;
}

.pad-l-16 {
  padding-left: 16px;
}

input:focus::placeholder {
  opacity: 0;
}

.input-placeholder {
  color: #e5e7eb;
  font-size: 15px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  opacity: 0.3;
}

.input-textarea {
  all: unset;
  display: flex;
  height: 96px;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 1px #e5e7eb solid;
  height: 98px;
  color: #212c0a;
  font-size: 16px;
  font-family: var(--font-secondary);
  font-weight: 400;
  word-wrap: break-word;
  padding: 16px;
  max-width: 336px !important;
}

.input-textarea::placeholder {
  color: #e5e7eb;
  font-size: 15px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  line-height: 25px;
  overflow: hidden;
}
