.switch-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch {
  width: 50px;
  height: 28px;
  border-radius: 20px;
  transition: background-color 0.3s;
  padding: 2px;
  background: #f3f4f6;
  border-radius: 999px;
}

.switch-checked {
  background: #ff5043;
}

.switch-unchecked {
  background: #f3f4f6;
}

.switch-knob {
  width: 24px;
  height: 24px;
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 999px;
  position: absolute;
  left: 1px;
  transition: left 0.3s;
}

.switch-checked .switch-knob {
  left: calc(100% - 26px);
}

.switch-label {
  margin-left: 8px;
}
