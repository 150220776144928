.profile-badge {
  display: inline-flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.profile-badge-avatar {
  display: inline-block;
  margin-right: 10px;
  max-height: 38px;
}
