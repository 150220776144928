.footer-copyright {
  position: relative;
}

.footer-copyright:before {
  content: "";
  position: absolute;
  top: 0;
  left: 12px;
  right: 12px;
  border-top: 1px #e5e7eb solid;
  height: 0;
}

.footer-component {
  color: rgba(31, 41, 55, 0.75);
  font-size: 14px;
  font-family: Helvetica Neue;
  font-weight: 400;
  line-height: 15.4px;
  word-wrap: break-word;
}

.footer-gray-area {
  padding: 16px 8px;
  background: #f9fafc;
  border-bottom: 1px #e5e7eb solid;
  color: rgba(31, 41, 55, 0.75);
  font-size: 14px;
  font-family: Helvetica Neue;
  font-weight: 400;
  line-height: 15.4px;
  word-wrap: break-word;
}
