.dropdown-component {
  position: relative;
  display: inline-block;
}

.dropdown-component-menu {
  position: absolute;
  top: 100%; /* Position below the button */
  left: 0;
  background: white;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Transition for opacity and scale */
  color: #1f2937;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
  width: 181px;
  z-index: 9999; /* High z-index to stay on top */
  opacity: 0;
  pointer-events: none;
  transform: scaleY(0); /* Initially collapsed */
  transform-origin: top; /* Animation origin from the top */
}

.dropdown-component-menu.show {
  opacity: 1;
  pointer-events: auto;
  transform: scaleY(1); /* Expand dropdown */
}

.dropdown-component-menu.open-up {
  top: auto; /* Removes the default top position */
  bottom: 100%; /* Positions above the button */
  transform-origin: bottom; /* Origin for the animation from bottom */
}

.dropdown-component-menu.adjust-left {
  left: auto;
  right: 0; /* Aligns the dropdown to the right if it overflows the viewport */
}

.dropdown-component-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-component-item {
  padding: 16px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid #e5e7eb;
}

.dropdown-component-item span {
  margin-left: 8px;
  font-size: 1rem;
  color: #555;
}

.dropdown-component-item:hover {
  background-color: #f0f0f0;
}

.dropdown-button {
  padding: 0 12px;
  color: #1f2937;
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  height: 36px;
  margin-right: 20px;
  border: 1px solid #1f2937;
  border-radius: 8px;
  background: none;
  cursor: pointer;
}

.dropdown-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
