.steps-container {
  display: block;
}

.step {
  height: 4px; /* Height of the step line */
  background-color: #e5e7eb; /* Default color of step */
  margin: 0 15px;
  transition: background-color 0.3s ease-in-out; /* Simple animation for color change */
  width: 76px;
  display: inline-block;
}

.step:first-child {
  margin-left: 0;
}

.step:last-child {
  margin-right: 0;
}

.step.completed {
  background-color: #1f2937; /* Color for completed steps */
}
