.text {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  line-height: 15.4px;
}

/* 
Texts with Helvetica Neue font family
*/

.medium-primary-font-bold {
  font-family: var(--font-primary);
  font-weight: 500;
}

.medium-primary-font {
  font-family: var(--font-primary);
  line-height: 19.6px;
}

.small-primary-font {
  font-size: 12px;
  font-family: var(--font-primary);
  line-height: 13.2px;
  line-height: 17px;
}

/* 
Texts with DM Sans font family
*/

.large-secondary-font {
  font-size: 16px;
  font-family: var(--font-secondary);
  letter-spacing: 0.08px;
}

/* 
Texts with Inter font family
*/

.medium-ternary-font-bold {
  font-family: var(--font-ternary);
  font-weight: 500;
}

.medium-ternary-font-extra-bold {
  font-family: var(--font-ternary);
  font-weight: 600;
  letter-spacing: 0.07px;
}

.medium-15-ternary-font-extra-bold {
  font-size: 15px;
  font-family: var(--font-ternary);
  font-weight: 600;
  letter-spacing: 0.07px;
}

.medium-15-ternary-font-bold {
  font-size: 15px;
  font-family: var(--font-ternary);
  font-weight: 500;
  letter-spacing: 0.07px;
}

.extra-large-ternary-font {
  font-size: 18px;
  font-family: var(--font-ternary);
  letter-spacing: 0.09px;
}

.extra-large-ternary-font-bold {
  color: #1f2937;
  font-size: 24px;
  font-family: var(--font-ternary);
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: 0.12px;
  word-wrap: break-word;
}

.extra-large-ternary-font-extra-bold {
  font-size: 18px;
  font-family: var(--font-ternary);
  letter-spacing: 0.09px;
  font-weight: 700;
}

.heading-ternary-font {
  font-size: 24px;
  font-family: var(--font-ternary);
  font-weight: 500;
  line-height: 33.6px;
  letter-spacing: 0.12px;
}

.heading-ternary-font-extra-bold {
  font-size: 24px;
  font-family: var(--font-ternary);
  font-weight: 700;
  letter-spacing: 0.12px;
}

.heading-ternary-font-600-bold {
  font-size: 24px;
  font-family: var(--font-ternary);
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 33.6px;
  word-wrap: break-word;
}

.small-ternary-font-bold {
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.06px;
}

.small-ternary-font-bold-underline {
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.06px;
  text-decoration: underline;
}

.large-ternary-font {
  font-size: 16px;
  font-family: var(--font-ternary);
}

.large-ternary-font-bold-600 {
  font-size: 16px;
  font-family: var(--font-ternary);
  font-weight: 600;
}

.large-ternary-font-bold {
  font-size: 16px;
  font-family: var(--font-ternary);
  font-weight: 500;
}

.double-large-ternary-font-bold {
  font-size: 20px;
  font-family: var(--font-ternary);
  font-weight: 500;
}

.double-large-ternary-font {
  font-size: 20px;
  font-family: var(--font-ternary);
  line-height: 28px;
}

.mobile-heading {
  font-size: 32px;
  font-family: var(--font-ternary);
  font-weight: 600;
  line-height: 44.8px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
}

.heading-quaternary-font {
  font-size: 32px;
  font-family: var(--font-quaternary);
  font-weight: 400;
  line-height: 44.8px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
}

.heading-secondary {
  font-size: 24px;
  font-family: var(--font-quaternary);
  font-weight: 400;
  letter-spacing: 0.12px;
  word-wrap: break-word;
}

.heading-extra-large {
  color: #1f2937;
  font-size: 36px;
  font-family: Lora;
  font-weight: 400;
  letter-spacing: 0.18px;
  word-wrap: break-word;
}

.premium-gradient {
  color: #ff5043;
  font-size: 11px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
  word-wrap: break-word;
}

.largest-header {
  font-size: 42px;
  font-family: Lora;
  font-weight: 400;
  line-height: 58.8px;
  word-wrap: break-word;
}
