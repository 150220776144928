.arrow-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent black transparent;
  margin: 0 5px;
  animation: bounce 1.5s infinite;
}

.arrow1 {
  border-color: transparent transparent black transparent;
}

.arrow2 {
  border-color: transparent transparent #ff5043 transparent;
  animation-delay: 0.75s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
