.regular-input {
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 1px #e5e7eb solid;
  width: 400px;
  height: 46px;
}
/* 
.regular-input-textArea {
  height: 368px;
}

.regular-input-textArea input {
  height: 368px;
} */
