.header-container {
  height: 86px;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 0 40px 0 40px;
  width: 100%;
  border-bottom: 1px #e5e7eb solid;
}

.create-page-button {
  height: 46px;
  width: 150px;
  background: #1f2937;
  border-radius: 99px;
  border: 1px solid;
  overflow: hidden;
  border: 1px solid;
}

.mobile-header-container {
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px #e5e7eb solid;
  width: 100%;
  background: #f9fafc;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  padding: 0 23px 0 27px;
  background: #f9fafc;
  align-items: center;
}

.header-options {
  display: flex;
  height: 38px;
  align-items: center;
}

.mobile-header-options {
  display: flex;
  align-items: center;
  height: auto;
}

.burger-menu {
  padding-top: 93px;
  background: #f9fafc;
  padding-bottom: 56px;
}
