.photo-box {
  display: none; /* Hide the default file input */
}

.photo-upload-label {
  height: 116px;
  width: 90%;
  background: #e5e7eb;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.upload-icon {
  font-size: 24px; /* Adjust size as needed */
  color: #4b5563; /* Adjust color as needed */
}

.photo-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.upload-profile-photo-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.upload-picture-button {
  width: 132px;
  height: 38px;
  color: white;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: #1f2937;
  border-radius: 8px;
}

.upload-new-picture-button {
  height: 38px;
  width: 132px;
  color: white;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: #1f2937;
  border-radius: 8px;
}

.profile-picture-delete-button {
  color: #ef4444;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  width: 72px;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 38px;
}

.drag-creator-photo-box {
  height: 240px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 12px;
  border: 1px #e5e7eb dotted;
}

.creator-photos-preview-container {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  justify-content: space-between;
}

.preview-photo {
  width: calc((100% - 3 * 17px) / 4);
  height: 86.15px;
  margin-bottom: 12.7px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}

.preview-photo:hover {
  border: 4px #e5e7eb solid;
}

.preview-photo:not(:nth-child(4n)) {
  margin-right: 17px;
}

.remove-photo {
  position: absolute;
  right: 3.15px;
  top: 3px;
}

.empty-preview-photo {
  border: 4px #e5e7eb solid;
}

.add-photo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
