.pricing-modal {
  animation: fadeIn 0.3s ease;
  padding: 33px 28px 15px 28px;
  background: white;
  border-radius: 20px;
  border: 1px #e5e7eb solid;
  max-width: calc(100vw - 36px);
  max-height: calc(100vh - 4px);
  overflow-y: auto;
}

.pricing-modal-header {
  text-align: center;
  padding-bottom: 13px;
  border-bottom: 1px #e5e7eb solid;
}

.premium-tag {
  display: inline-flex;
  padding: 6px 12px;
  background: linear-gradient(
    101deg,
    rgba(255, 80, 67, 0.6) 0%,
    rgba(107, 102, 220, 0.6) 45%,
    rgba(26, 188, 254, 0.6) 100%
  );
  border-radius: 8px;
  border: 1px rgba(31, 41, 55, 0.04) solid;
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  word-wrap: break-word;
}

.toggle-section {
  display: flex;
  text-align: center;
  justify-content: center;
  color: #1f2937;
  font-size: 10px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.05px;
  word-wrap: break-word;
  margin-top: 25px;
  max-height: 28px;
}

.pricing-switch {
  display: inline-block;
  margin: -7px 16px 0 16px;
}

.pricing-modal-body {
  padding: 21px 0px;
}

.loader-container {
  background: white !important;
}
