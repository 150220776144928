.landing-page {
  margin-top: 86px;
}

.landing-input {
  width: 382px;
  height: 64px;
  position: relative;
  background: white;
  border-radius: 99px;
  border: 2px #9ca3af solid;
}

.landing-input input::placeholder {
  color: #9ca3af;
  font-size: 16px;
  font-family: DM Sans;
  font-weight: 500;
  word-wrap: break-word;
}

.landing-input-container {
  margin-top: 60px;
  margin-bottom: 77px;
}

.explore-designers-button {
  width: 146px;
  height: 46px;
  border-radius: 99px;
  overflow: hidden;
  border: 1px #9ca3af solid;
}

.text-fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
