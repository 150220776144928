.profile-container {
  display: flex;
  padding: 18px 109px 18px 109px;
}

.preview-profile-container {
  padding: 78px 109px 18px 109px;
}

.creator-photo {
  margin-bottom: 22px; /* Space between images */
}

.creator-photo img {
  border-radius: 12px;
  border: 1px #e5e7eb solid;
  width: 100%;
  height: auto;
}

.profile-tile-container {
  width: 400px;
  max-width: 100vw;
  margin-right: 15px;
}

.profile-tile-1 {
  margin-bottom: 16px;
}

.profile-tile-2 {
  width: 400px;
  background: #f9fafc;
  border: 1px #e5e7eb solid;
  padding: 23px 0px;
  border-radius: 12px;
}

.profile-buttons {
  margin-bottom: 20px;
  border-bottom: 1px #e5e7eb solid;
  width: 100%;
  padding: 0 32px 20px 32px;
}

.profile-button {
  background: #e5e7eb;
  border-radius: 40px;
  color: #1f2937;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  height: 38px;
  padding: 0 18px;
  margin-right: 20px;
  display: inline-block;
}

.profile-button-selected {
  background: #ff5043;
  color: white;
}

.stat-box {
  margin-right: 29px;
}

.stat-box:last-child {
  margin-right: 0;
}

.state-box-value {
  color: #1f2937;
  font-size: 24px;
  font-family: Inter;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: 0.12px;
  word-wrap: break-word;
  margin-bottom: 8px;
}

.state-box-label {
  color: #575f69;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
}

.profile-stats {
  margin-bottom: 15px;
}

.profile-detail-label {
  margin-bottom: 8px;
  color: #1f2937;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
}

.profile-detail-value {
  color: #575f69;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
  margin-bottom: 30px;
}

.profile-links-section {
  border-top: 1px #e5e7eb solid;
  padding-top: 30px;
}

.profile-links-value {
  display: inline-flex;
  color: #2563eb;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
  cursor: pointer;
  max-width: 90%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.photos-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.photo-column {
  display: flex;
  flex-direction: column;
  /* Adjust the width as necessary, this is just an example */
  width: calc(50% - 10.5px);
  position: relative;
}

.creator-image-container {
  position: relative;
}

.creator-image-container:hover {
  .image-expand-icon {
    display: inline-block;
  }
}

.image-expand-icon {
  position: absolute;
  right: 16px;
  bottom: 14px;
  display: none;
  cursor: pointer;
}

.creator-photo {
  margin-bottom: 22px; /* Space between images */
}
.creator-photo img {
  border-radius: 12px;
  border: 1px #e5e7eb solid;
  width: 100%;
  height: auto;
}

.creator-questions-container {
  margin-top: 78px;
}

.create-profile-container {
  padding: 0 108px;
}

.create-profile-tracker {
  top: 84px;
}

.new-profile-footer {
  display: flex;
  justify-content: space-between;
  height: 72px;
  width: 500px;
  position: fixed;
  bottom: 0;
  left: 50%;
  padding: 12px 10px;
  transform: translate(-50%);
}

.new-profile-footer-edit-button {
  height: 48px;
  width: 123px;
  color: #6b66dc;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px #6b66dc solid;
}

.new-profile-footer-publish-button {
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  background: #6b66dc;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  height: 48px;
  width: 275px;
}

.profile-building {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82vh;
  width: 100%;
  color: #9ca3af;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  word-wrap: break-word;
}

.image-expander {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100vw;
  height: 100vh;
}

.expanded-image {
  max-width: 100vw;
  height: auto;
  max-height: 95vh;
  object-fit: contain;
  border-radius: 12px;
  margin: 0 22px;
  transition: opacity 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0.5;
}

.fade-enter-active {
  opacity: 1;
}

.expanded-image-container {
  position: relative;
}

.photo-expand-cross {
  position: absolute;
  top: -12px;
  right: -18px;
}

.profile-tile-summary-role {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 92px;
  /* padding: 43px 28px 17px 29px; */
  background: #f9fafc;
  border: 1px #e5e7eb solid;
  border-radius: 12px 12px 0px 0px;
}

.profile-tile-details {
  padding: 56px 15.84px 25px 31.34px;
  background-color: white;
  border: 1px #e5e7eb solid;
  border-top: none;
  border-radius: 0px 0px 12px 12px;
}

.premium-badge {
  margin-left: 11px;
  display: inline-block;
}

.premium-badge-summary {
  position: absolute;
  left: auto;
}

.premium-banner {
  display: flex;
  height: 48px;
  width: 100%;
  align-items: center;
}

.looking-for-job-banner {
  display: flex;
  height: 48px;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
  font-family: Inter;
  font-weight: 600;
  line-height: 18.2px;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: linear-gradient(
    90deg,
    #511cb6 0%,
    #b733a5 40%,
    #e06953 77%,
    #c73037 100%
  );
}

.hiring-banner-theme {
  background: linear-gradient(90deg, #243869 0%, #1869d3 54%, #16ebeb 100%);
}

.position-at {
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
  word-wrap: break-word;
  color: #111827;
  max-width: 250px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}

.theme-box-wrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
}

.theme-box {
  height: 46px;
  width: 46px;
  border-radius: 1000px;
  display: inline-block;
  transition: border 0.5s;
}

.selected-theme {
  border: 3px solid rgba(37, 99, 235, 0.5);
}
