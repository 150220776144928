.role-displayer {
  display: inline-flex;
  height: 32px;
  padding: 0 18px;
  overflow: hidden;
  color: #1f2937;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 0.07px;
  word-wrap: break-word;
  background: white;
  border-radius: 999px;
  border: 1px rgba(31, 41, 55, 0.04);
  align-items: center;
  color: #575f69;
}

.red-displayer {
  color: #ff5043 !important;
  background: rgba(255, 114, 98, 0.1);
  border: 1px rgba(31, 41, 55, 0.04);
}
